.cookies-policy-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.cookies-policy-page-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.cookies-policy-page-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.cookies-policy-page-text {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
}
.cookies-policy-page-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text01 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text04 {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text05 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text07 {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text08 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.cookies-policy-page-container11 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.cookies-policy-page-text11 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container12 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text13 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-text15 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-text17 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text19 {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text20 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text45 {
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookies-policy-page-text46 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  text-align: left;
}
.cookies-policy-page-footer-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
