.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.home-container01 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(243, 243, 243, 0.8);
}
.home-text {
  text-align: center;
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text2 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.home-text3 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}
.home-container02 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container03 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-image {
  top: 0px;
  left: auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 80vh;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}
.home-products-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container04 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text5 {
  margin-top: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container06 {
  display: flex;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.home-container06:hover {
  transform: scale(1.04);
}
.home-container07 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  background: rgba(243,243,243,0.8);
  border-radius: var(--dl-radius-radius-radius1);
}
.home-container08 {
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image1 {
  width: 400px;
  object-fit: cover;
}
.home-text6 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-image2 {
  width: 300px;
  object-fit: cover;
}
.home-link1 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-container09 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-footer-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-container01 {
    max-width: 960px;
  }
  .home-card {
    width: 100%;
  }
  .home-products-contaier {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container09 {
    width: 45%;
    margin-left: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .home-card {
    align-items: center;
  }
  .home-text3 {
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-image {
    display: none;
  }
  .home-container04 {
    width: 80%;
  }
  .home-container05 {
    flex-direction: column;
  }
  .home-container09 {
    width: 80%;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .home-card {
    padding: var(--dl-space-space-unit);
  }
  .home-container02 {
    align-items: center;
    flex-direction: column;
  }
  .home-container03 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
