.use-cookies-bar-container {
  flex: 0 0 auto;
  width: 100%;
  bottom: 0px;
  height: auto;
  display: none;
  z-index: 1000;
  position: fixed;
  align-items: flex-start;
  border-radius: 21px;
  flex-direction: row;
  background-color: rgba(250, 121, 48, 0.79);
}
.use-cookies-bar-container1 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.use-cookies-bar-link {
  color: var(--dl-color-gray-black);
  text-decoration: none;
}
.use-cookies-bar-container2 {
  flex: 0 0 auto;
  width: 20%;
  height: auto;
  display: flex;
  margin-top: 4px;
  align-items: flex-end;
  margin-bottom: 4px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.use-cookies-bar-button {
  color: var(--dl-color-gray-black);
  border-radius: 16px;
}
