.outline-green-button-container {
  display: flex;
  position: relative;
}
.outline-green-button-button {
  color: #293f20;
  cursor: pointer;
  outline: none;
  font-size: small;
  min-width: 150px;
  transition: 0.3s;
  font-weight: 600;
  padding-top: var(--dl-space-space-triplequarterunit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius75);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-triplequarterunit);
  background-color: rgba(80, 164, 45, 0.95);
}
.outline-green-button-button:hover {
  transform: scale(1.02);
}
.outline-green-button-root-class-name {
  background-color: #d9d9d9;
}
.outline-green-button-root-class-name1 {
  background-color: #d9d9d9;
}
