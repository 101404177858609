.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  justify-content: space-between;
}
.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link {
  display: contents;
}
.footer-image {
  width: 150px;
  cursor: pointer;
  object-fit: cover;
  text-decoration: none;
}
.footer-container2 {
  display: flex;
  justify-content: space-between;
}
.footer-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text1 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-link1 {
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
}
.footer-link2 {
  cursor: pointer;
  padding-top: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.footer-text3 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-link3 {
  font-style: normal;
  font-weight: 300;
}
.footer-image1 {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
}
@media(max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container4 {
    margin-left: var(--dl-space-space-doubleunit);
  }
}
